/* ---------------- middle div styles ---------------------- */
.anandlogo_img {
  width: 10rem;
  height: 6rem;
}

.anandlogo_img:hover {
  cursor: pointer;
}
.home_navbar_links{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.home_middle {
  padding-top: 1rem;
}

.search_bar {
  display: flex;
  margin: 1rem 5rem 0.8rem 1rem;
  justify-content: center;
  width: 100%;
}

.home_middle_carousel {
  padding: 0.1rem 0;
}

/* .home_middle_sub_bottom {
  display: grid;
  column-gap: 1px;
  padding: 10px;
  grid-template-columns: auto auto auto;
} */

.breakfast { grid-area: header;}
.bread {grid-area: left;}
.tea { grid-area: menu; }
.cake { grid-area: main; }
.ags { grid-area: right; }

.grid-container {
  display: grid;
  grid-template-areas:
    'header header header left left left'
    'menu menu main main right right';
  gap: 10px;
  padding: 10px;
}

.adv_grid_image{
  object-fit: fill;
  width: 100%;
  height: 22rem;
}

.home_middle_bottom_img {
  margin: 0.5rem;
}

.home_middle_bottom_img:hover {
  cursor: pointer;
}

.home_middle_bottom_img img {
  width: 100%;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.navbar_icons {
  margin: 0 0.4rem;
  cursor: pointer;
}

.home_middle_sub_icons {
  height: 30%;
  /* padding-left: 0.5rem; */
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

/* ---------------- carousel css --------------------------- */

.carousel__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.carousel__container_div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}
.carousel__container_image,
.poster-image {
  width: 98vw;
  height: 25vw;
  margin-bottom: 1rem;
  object-fit: fill;
}

.circle {
  cursor: pointer;
  width: 10rem;
  height: 10rem;
  line-height: 100px;
  font-size: 15px;
  margin-right: 0.5em;
  text-align: center;
  align-items: center;
  color: white;
}

.multi_carousel_name {
  padding: 0 1rem;
}

/*--------------------- navbar css -------------------------- */

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  background-color: greenyellow;
}

.navbar_list {
  cursor: pointer;
}

.navbar-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
}

.navbar_content {
  color: black;
  background-color: greenyellow;
  margin: 0 0.3rem;
  padding: 0 3rem;
  border-top-left-radius: 10px 10px;
  border-top-right-radius: 10px 10px;
}

#navbar_sidebar {
  justify-content: center;
  display: flex;
  align-items: center;
}

.navbar_text,
#basic-nav-dropdown {
  color: black;
}

.navbar_text {
  padding: 0.5rem 1.7rem;
}

/* ----------------- profile screeen css ----------------*/
.profile_container {
  margin-top: 1rem;
}

.profile_btn_setting {
  cursor: pointer;
  align-items: center;
  width: 30%;
  padding: 10px 20px;
  background-color: #06fa57;
  border: none;
  color: black;
  margin: 0 0.5rem;
  border-radius: 20px;
}

.profile_btn_signout {
  cursor: pointer;
  align-items: center;
  width: 30%;
  padding: 10px 20px;
  background-color: #d03a33;
  border: none;
  color: white;
  margin: 0 0.5rem;
  border-radius: 20px;
}

.profile_category {
  cursor: pointer;
  align-items: center;
  margin: 0 0.3rem;
  padding: 10px 1rem;
  background-color: #eac302;
  border: none;
  color: black;
  font-weight: 500;
  border-radius: 20px;
}

.profile_div {
  margin: 1rem 0;
}

.rectangular {
  cursor: pointer;
  width: 80px;
  height: 80px;
  line-height: 100px;
  border-radius: 10px;
  border: 1px solid black;
  font-size: 15px;
  background-color: white;
  margin-right: 1em;
  text-align: center;
  align-items: center;
  color: white;
}

/* ----------------- for fruits screen --------------------- */

.selection_items {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
}

.select_filter {
  background-color: greenyellow;
  border: none;
  width: 19%;
  height: 2rem;
  border-radius: 10px;
  padding: 0 0.5rem;
  align-items: center;
}

.show {
  background-color: greenyellow;
  border-radius: 20px;
}

.show a {
  color: black;
}

/* --------- product list of every card items ---------------- */

.product_list {
  width: 12rem;
  background-color: #eafcbe;
  border-radius: 20px;
  margin: 0.5rem;
}

.product_list_fav {
  width: 12rem;
  background-color: #eafcbe;
  border-radius: 20px;
  margin: 0.5rem;
}

.product_list img {
  padding: 0.5rem;
  border-radius: 20px;
}

.card_btn {
  margin: 0;
  width: 80%;
  justify-content: center;
  display: flex;
}

.product_div,
.favourite_container {
  display: grid;
  column-gap: 1px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto auto auto auto auto;
}

.fruit_show {
  width: 55%;
  color: white;
  background-color: #00bdc2;
  border-radius: 10px;
  padding: 0 0.5rem;
  height: 2rem;
  align-items: center;
}

.btn_card_dec,
.btn_card_div,
.btn_card_inc,
.btn_card_add {
  background-color: #ffa100;
  color: white;
  font-weight: bold;
}

button:focus {
  outline: none;
}

.btn_card_dec {
  border: none;
  height: 2.1rem;
  margin-left: 0.5rem;
  border-top-left-radius: 10px 10px;
  border-bottom-left-radius: 10px 10px;
}

.btn_card_inc {
  border: none;
  height: 2.1rem;
  border-top-right-radius: 10px 10px;
  border-bottom-right-radius: 10px 10px;
}

.btn_card_div {
  border: none;
  height: 2.1rem;
  padding: 0.4rem;
  display: flex;
  align-items: center;
}

.btn_card_add {
  height: 2.1rem;
  margin-left: 0.5rem;
  border: none;
  padding: 0 1rem;
  border-radius: 0.5rem;
}

.card-img-top {
  object-fit: fill;
  height: 12rem;
  width: 14rem;
}

.cart-img-top {
  object-fit: fill;
  height: 7rem;
  margin: 0.2rem 1rem;
  width: 10rem;
}

.product_list {
  height: 20rem;
  width: 14rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.card_icon {
  position: absolute;
  margin: 0.5rem 0;
  right: 10px;
  z-index: 10;
  color: red;
}

.card_title {
  font-size: 1rem;
  text-align: center;
}

.card_text {
  font-size: 0.8rem;
  text-align: center;
}

.home_middle_bottom {
  padding-left: 0.5rem;
  margin-top: 0.3rem;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: 0;
  /* width: 100vw;
  height: 100vh; */
}

.home_middle_bottom::-webkit-scrollbar {
  display: none;
}

.not_logged_in {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* --------------------- about css ------------------------*/
.about_header,
.about_achievment,
.about_feedback {
  background-color: red;
  width: 25%;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  color: white;
  font-weight: 400;
  pointer-events: none;
}

.about_achievment {
  color: black;
  background-color: #a1f9e4;
  width: 30%;
}

.about_feedback {
  width: 35%;
  color: black;
  background-color: #e7ee8b;
}

.about_middle img {
  width: 50%;
  height: 50%;
  border-radius: 10px;
  object-fit: fill;
}

.about_middle_div img {
  width: 50%;
  height: 50%;
  border-radius: 10px;
}

.about_middle_header {
  vertical-align: middle;
  font-size: 1.2rem;
  padding: 0.5rem;
  margin-left: 0.5rem;
  background-color: #f3f8d2;
  border-radius: 0.8rem;
  text-align: justify;
}

.about_bottom_header {
  font-size: 1.2rem;
  padding: 0.5rem;
  background-color: #f3f8d2;
  border-radius: 0.8rem;
  text-align: justify;
}

.about_middle,
.about_middle_div {
  display: flex;
  flex-direction: row;
}

.rectangular_feedback {
  width: 200px;
  cursor: pointer;
  height: 100px;
  line-height: 100px;
  border-radius: 10px;
  border: 1px solid black;
  font-size: 15px;
  background-color: white;
  margin-right: 1em;
  text-align: center;
  align-items: center;
}

.rectangular_feedback h4 {
  margin-top: 0.5rem;
  text-align: center;
  font-size: 1rem;
}

.rectangular_feedback h6 {
  text-align: end;
  padding: 2rem 1rem;
}

.rectangular_achieve {
  cursor: pointer;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 10px;
  border: 1px solid black;
  font-size: 15px;
  background-color: white;
  margin-right: 1em;
  text-align: center;
  align-items: center;
  color: white;
}

.about_bottom {
  margin: 0.5rem 0;
}

.about_container {
  margin: 1rem 0;
}

/* -------------------- privacy policy css -------------- */
.privacy_header {
  background-color: #cb4f4f;
  width: 35%;
  border-radius: 2px;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  color: white;
  font-weight: 400;
}

.privacy_policy {
  font-weight: 500;
  margin: 1.5rem 0;
}

.privacy_img_div {
  text-align: justify;
}

.privacy_img {
  width: 90%;
  height: 50vh;
}

/* ----------------- Store Location css ------------------ */

.store_location_header {
  text-align: center;
  background-color: #fb9390;
  font-weight: 400;
  color: white;
  border-radius: 3rem;
  padding: 0.1rem 0;
}

.store_location_bottom {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
}

.store_location_bottom img {
  width: 25%;
  border-radius: 10px;
}

.store_location_bottom_content {
  padding: 1rem;
  margin-left: 0.2rem;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.store_location_bottom {
  background-color: #f3ecd9;
  border-radius: 20px;
  margin: 1.5rem 0;
}

.golocation-icon {
  color: red;
  margin-right: 1rem;
}

.store_location_bottom_content_right {
  text-align: end;
}

.store_location_bottom_content_right_header {
  font-size: 1rem;
}

.store_location_pointing {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
}

/* -------------------- Terms And Conditions css   -------------- */
.termsAndConditions_header {
  background-color: #cb4f4f;
  width: fit-content;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: white;
  font-weight: 400;
  margin-top: 1rem;
}

.termsAndConditions_accounts {
  margin-bottom: 1.5rem;
  width: 100%;
  border-radius: 2px;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  color: white;
  padding: 1rem;
  font-weight: 400;
  border: 1px solid rgb(53, 51, 51);
  border-radius: 24px;
}

.termsAndConditions_accounts_header {
  background-color: #07868f;
  text-align: center;
  width: min-content;
  margin: auto;
  padding-right: 1rem;
  border-radius: 2px;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  color: white;
  font-weight: 400;
}

.termsAndConditions {
  font-weight: 500;
  color: black;
  margin: 1.5rem 0;
  text-align: justify;
}

.termsAndConditions_date {
  display: "flex";
  justify-content: "space-between";
}

.termsAndConditions_img_div {
  text-align: center;
}

.termsAndConditions_img {
  width: 90%;
  height: 50vh;
}

.category_carousel {
  display: flex;
  flex-direction: row;
  margin: auto;
  text-align: center;
  
}

.address_header {
  margin-top: 1rem;
}

.address_card {
  border: 1px solid black;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  height: 5rem;
}

.address_card_green {
  border: 1px solid black;
  background-color: #4ba527;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  height: 5rem;
}

.address_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.address_card_content {
  text-align: center;
}

.address_btn_div,
.cart_content,
.cart_order_price {
  text-align: end;
}

.cart_order_price {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.address_btn {
  margin-left: 0.2rem;
  cursor: pointer;
  align-items: center;
  padding: 8px 20px;
  background-color: #4ba527;
  border: none;
  font-weight: 600;
  border-radius: 20px;
}

.loyaltypoint_header {
  margin-top: 1rem;
}

.cart {
  display: flex;
  flex-direction: row;
  padding: 2rem;
}

.cart_header_div {
  padding-right: 1rem;
  display: flex;
  flex-direction: row;
}

.cart_header {
  margin-top: 1rem;
}

.cart_order_div {
  margin: 1.5rem 0;
}

.cart_product_image {
  height: 8rem;
  width: 8rem;
}

.cart-name-col {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 70%;
  align-items: flex-start;
}

.cart {
  padding: 0 1rem;
}

.cart_content {
  margin-left: 10rem;
}

.cart_header_btn {
  cursor: pointer;
  align-items: center;
  padding: 8px 30px;
  background-color: #68c444;
  border: none;
  font-weight: 600;
  border-radius: 20px;
}

.cart_order_name {
  text-align: start;
}

.cart_price_value {
  color: #9cec7c;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.order_history_check {
  margin: 1rem;
  padding: 0.5rem 1rem;
  display: flex;
  height: 5rem;
  flex-direction: row;
  border-radius: 0.5rem;
  background-color: #9cec7c;
}

.cart_order {
  margin: 1rem;
  padding: 0.5rem 1rem;
  display: flex;
  height: 10rem;
  flex-direction: row;
  border-radius: 0.5rem;
  background-color: #9cec7c;
}

/*---------------------------- login css --------------------------- */
.login_btn {
  cursor: pointer;
  align-items: center;
  width: 30%;
  padding: 10px 15px;
  background-color: #d03a33;
  border: none;
  color: white;
  font-size: 1.5rem;
  border-radius: 20px;
}

.login_main {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #3c7dd9;
}

.login_btn_a {
  width: 20%;
  background-color: black;
  color: white;
}

.login__header {
  color: white;
}

.signup_btn_div {
  align-items: center;
  justify-content: center;
  display: flex;
}

.signup__main__cotainer {
  width: 100vw;
  height: 100vh;
  background-color: #3c7dd9;
}

.signup__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3em;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.signup_btn {
  cursor: pointer;
  align-items: center;
  width: 30%;
  background: linear-gradient(to right, #2193b0, #6dd5ed);
  border: none;
  color: white;
  font-size: 1.5rem;
  border-radius: 10px;
  margin: 0 7rem;
}

.signup_header {
  margin: 1rem 5rem;
}

.signup_container {
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.signup_main input {
  border-radius: 5px;
  margin-left: 0.5rem;
}

.signup_main label {
  margin: 0.8rem 0;
}

.signup_main {
  border-radius: 5px;
  padding: 1rem;
}

/* ---------------------- add address css ----------------------- */

.add_address_container {
  margin: 2rem 3rem;
  padding: 0.5rem;
  height: 90%;
  border: 1px solid grey;
  border-radius: 0.75rem;
}

.box_input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.add_address_btn {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  background-color: #4ba527;
  border: none;
  width: 25%;
  font-weight: 600;
  border-radius: 20px;
}

.add_address_btn_div {
  display: flex;
  justify-content: center;
}

/* --------------------------- order history -------------------------- */

.order_history_main {
  margin: 1.5rem 0rem;
}

.order_history {
  border: 2px solid black;
  margin: 1rem;
  padding: 0.5rem;
  display: flex;
  height: 8rem;
  flex-direction: row;
  border-radius: 0.5rem;
}

.order_history_img {
  border-radius: 5px;
  width: 15%;
  height: 6rem;
}

.order_cancel {
  display: flex;
  color: red;
  font-weight: 500;
  margin: 1rem 8rem;
  font-size: 1.2rem;
  justify-content: center;
}

.order_cancel_btn {
  color: white;
  background-color: red;
  cursor: pointer;
  width: 25%;
  align-items: center;
  padding: 6px 10px;
  border: none;
  border-radius: 20px;
}

.order_right_div {
  text-align: center;
}

.order_history_detail {
  margin: 0 2.5rem;
}

.order_history_content {
  display: flex;
  flex-direction: row;
}

.order_history_detail p {
  font-size: 1rem;
  margin: 0;
}

.timeline {
  height: 5rem;
  width: 80%;
  transform: rotate(-90deg);
}

.timeline_content {
  text-align: center;
  font-size: 0.2rem;
  transform: rotate(-270deg);
}

.mdoutlinedone,
.mddelivery,
.mddoneall {
  background-color: green;
  transform: rotate(-270deg);
}

.contact_main {
  align-items: center;
  display: flex;
  justify-content: center;
}

.login__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3em;
  border: solid black 2px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.review_multi_carousel__image {
  height: 20rem;
  width: 45vw;
  margin: 1em;
  margin-left: 6em;
  padding: 1em;
}

.category__container_grid_content_container_image {
  height: 15rem;
  width: 15rem;
}

.category__container_grid_content_container {
  padding: 1em 1em 1em 1em;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.category__container_grid_content_container_title {
  color: white;
  font-size: 1.4rem;
  text-align: center;
  width: 15rem;
  background-color: red;
}

.footer__container {
  display: flex;
  background-color: #220e3f;
  color: white;
  margin-top: 2rem;
  padding: 3em 4em;
  text-decoration: none;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .footer__container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.footer__container_logo_contianer {
  flex: 1;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.footer__container_logo_contianer_details {
  margin-top: 1.5em;
}

.footer__container_links_contianer {
  flex: 2;
  text-decoration: none;
}


.footer__container_links_contianer {
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;

}


@media screen and (max-width: 800px) {
  .footer__container_links_contianer {
    margin: 2em 0 0 0;
    padding: 0;
  }
}


.footer__container_links_contianer_title {
  font-weight: bold;
  margin-bottom: 1.2em;
}

@media screen and (max-width: 800px) {
  .footer__container_links_contianer_content_wraper {
    margin: 1em 5em 0 0;
    padding: 0;
  }
}


@media screen and (max-width: 580px) {
  .footer__container_links_contianer_content_wraper {
    margin: 1em 1em 0 0;
  }
}

.footer__container_links_contianer_list_item {
  margin-bottom: 1em;
}

.footer__container_links_contianer_list_item:hover {
  cursor: pointer;
}

/* .footer__container_logo_contianer>img{
  height: 3rem;
  width: 15rem;
} */

.footer-logo {
  height: 4rem;
  width: 6rem;
}

.footer_social_media_pink {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 10px;
}
.facebook , .instagram{
  text-decoration: none;
  margin: 0 1em 0 0;
}

.facebook img{
  height: 4rem;
  width: 4rem;
}

.instagram img{
  height: 4rem;
  width: 4rem;
}

.footer__subcontainer{
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: row;
  width: 30rem;
}

@media screen and (max-width: 800px) {
  .footer__subcontainer{
    display: flex;
    
    justify-content: space-evenly;
    flex-direction: column;
    width: 10rem;
  }
}