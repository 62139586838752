@media screen and (max-width: 800px) {
  .navbar-row {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .home_navbar_links{
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
  }
}
@media screen and (max-width: 600px) {
  .select_filter {
    width: 32%;
    height: 2rem;
    padding: 0 0.5rem;
  }

    .adv_grid_image{
      height: 6rem;
    }
 
  .about_header {
    width: 30%;
    border-radius: 5px;
  }

  .product_div {
    display: grid;
    grid-template-columns: auto auto;
  }

  .order_history_detail {
    margin: 0 0.5rem;
  }

  .timeline {
    height: 5rem;
    margin: 0 -3rem;
  }

  .profile_container {
    display: flex;
    flex-direction: column;
  }

  .profile_btn {
    display: flex;
    flex-direction: column;
  }

  .profile_btn_setting {
    width: 70%;
  }

  .profile_btn_signout {
    width: 70%;
    margin: 0.5rem;
  }

  


  .cart-img-top {
    margin: 0.5rem 1.5rem;
    width: 7rem;
    height: 7rem;
  }

  .card-img-top {
    width: 12rem;
    height: 12rem;
  }

  .card_btn {
    width: 100%;
  }

  .fruit_show {
    width: 48%;
  }

  .not_logged_in {
    height: 50vh;
  }

  .cart_product_image {
    margin: 0.8rem 0.2rem;
    height: 5rem;
    width: 5rem;
  }

  .cart_content {
    margin-left: 0;
  }

  .order_history_img {
    width: 30%;
  }

  .order_history_content {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .home_middle {
    margin: 0 0.5rem;
  }



  .circle {
    width: 85px;
    height: 85px;
  }

  .multi_carousel_name {
    padding: 0 0.7rem;
  }

  .home_middle_sub_bottom,
  .favourite_container {
    display: grid;
    grid-template-columns: auto auto;
  }

  .home_middle_bottom_img img {
    width: 85%;
    border-radius: 10px;
  }

  #navbar_sidebar {
    margin: 0 0.8rem;
    display: flex;
    justify-content: flex-start;
  }

  .navbar_toggle {
    font-size: 1rem;
    background-color: grey;
  }

  .navbar_content {
    padding: 0;
  }

  .about_achievment {
    width: 35%;
    border-radius: 5px;
  }

  .about_feedback {
    width: 50%;
    border-radius: 5px;
  }

  .order_cancel_btn {
    width: 60%;
    margin: 0 3rem;
  }
  .order_history {
    margin: 1rem 0.2rem;
    padding: 0.3rem;
    height: 8rem;
  }
}

@media screen and (max-width: 480px) {
  .circle {
    width: 75px;
    height: 75px;
  }

  .home_middle_sub_bottom {
    margin-top: 1rem;
    display: grid;
    column-gap: 1px;
    padding: 1px;
    grid-template-columns: auto auto;
  }

  .home_middle_bottom_img {
    margin: 0.3rem;
  }

  .home_middle_bottom_img img {
    width: 100%;
  }

  /* ---------------------- about screen css --------------------- */

  .about_header {
    width: 40%;
    border-radius: 5px;
  }

  .about_feedback {
    width: 40%;
    border-radius: 5px;
  }

  .about_achievment {
    width: 42%;
    border-radius: 5px;
  }

  .about_feedback {
    width: 60%;
    border-radius: 5px;
  }

  .about_middle {
    display: flex;
    margin: 0 1rem;
    flex-direction: column;
  }

  .about_middle_div {
    display: flex;
    margin: 0 1rem;
    flex-direction: column-reverse;
  }

  .about_middle img {
    width: 100%;
    height: 50%;
    border-radius: 10px;
  }

  .about_middle_div img {
    margin: 1rem 0;
    width: 100%;
    height: 50%;
    border-radius: 10px;
  }

  .about_middle_header {
    font-size: 0.85rem;
    padding: 0.5rem;
    margin-left: 0;
    margin-top: 0.5rem;
    background-color: #f3f8d2;
    border-radius: 10px;
  }

  /* -------------------- privacy policy css -------------- */

  .privacy_header {
    width: 55%;
  }

  .privacy_policy,
  .termsAndConditions {
    font-weight: 500;
    margin: 1rem 0;
    font-size: 1rem;
  }

  .privacy_img,
  .termsAndConditions_img {
    width: 70%;
  }

  .store_location_header {
    text-align: center;
    background-color: #fb9390;
    padding: 0.1rem 0;
    width: 60%;
  }

  .store_header,
  .store_location {
    display: flex;
    justify-content: center;
  }

  .store_location_map {
    width: 95%;
    border-radius: 10px;
  }

  .store_location_bottom {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 1.5rem 2rem;
  }

  .store_location_bottom img {
    margin: 0.5rem 2.5rem;
    width: 80%;
  }

  .store_location_bottom_content {
    padding: 0.5rem;
    margin-left: 0;
    width: 90%;
    margin-top: 1rem;
  }

  .navbar_toggle {
    font-size: 1rem;
    background-color: grey;
  }

  /* ------------------------- cart css ------------------------ */

  .cart h3 {
    font-size: 1rem;
  }

  .cart_icon {
    width: 15%;
  }

  .cart {
    display: flex;
    flex-direction: column;
  }

  .cart_header_div {
    height: 3rem;
  }

  .cart {
    padding: 0 0.2rem;
  }

  .cart_header_btn {
    padding: 8px 30px;
  }

  .cart-name-col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50%;
    align-items: flex-start;
  }

  .cart_order {
    margin: 1rem 0;
    padding: 0.2rem;
    height: 8.5rem;
  }

  .cart-productname {
    font-size: 1rem;
  }

  /* ---------------------- address css -------------------------- */

  .address_div {
    display: flex;
    flex-direction: column;
  }

  .address_div h3 {
    font-size: 1.5rem;
  }

  .address_btn_div {
    margin: 2rem 0;
  }

  .address_card,
  .address_card_green {
    width: 80%;
  }

  .add_address_container {
    margin: 0.8rem;
    height: 80%;
    padding: 0.3rem;
  }

  .add_address_container label {
    margin: 0.7rem;
  }

  .add_address_container input {
    width: 65%;
    height: 2rem;
  }

  .add_address_btn_div {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
  }

  .add_address_btn {
    padding: 10px 4px;
    font-size: 1.1rem;
  }

  /*--------------------------- profile css -------------------- */

  .profile_container {
    display: flex;
    flex-direction: column;
  }

  .profile_btn {
    display: flex;
    flex-direction: column;
  }

  .profile_btn_setting {
    width: 70%;
  }

  .profile_btn_signout {
    width: 70%;
    margin: 0.5rem;
  }

  /*  ----------------------login css ----------------------*/

  .login_btn {
    width: 40%;
    padding: 10px 20px;
    margin: 10rem 6rem;
  }

  /* ----------------------- card css ------------------------- */

  .card_icon {
    font-size: 1.5rem;
  }

  .product_div {
    display: grid;
    grid-template-columns: auto auto;
  }

  .favourite_container {
    display: grid;
    grid-template-columns: auto auto;
  }

  .product_list_fav {
    width: 10rem;
    background-color: #eafcbe;
    border-radius: 20px;
    margin: 0.5rem;
  }
  .product_list {
    width: 12rem;
    margin: 0.2rem;
  }

  .signup_main {
    margin: 0 0.5rem;
  }

  .timeline {
    height: 5rem;
    margin: 0 -2rem;
  }

  .order_cancel_btn {
    width: 70%;
  }
}
