body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* overflow-y: hidden; */
}

html {
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home_container {
  /* height: 100vh; */
}

.home_middle {
  /* height: 100vh; */
}

.cart_product_image {
  height: 3rem;
  width: 3rem;
  margin-right: 0.3rem;
}

.selectedWallet {
  background-color: #4caf50;
}

.un-selectedWallet {
  background-color: red;
}

.payment_selection_container {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:20px;
	right:20px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
